var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppMainContainer',{attrs:{"center-class":"offer"},scopedSlots:_vm._u([{key:"center",fn:function(){return [(_vm.isLoading)?_c('div',{staticClass:"user-info__action-panel"},[_c('AppLoader')],1):_c('div',[(_vm.userInfo)?_c('UserInfoList',{attrs:{"user-info":_vm.userInfo,"edit-list":{
          office: true,
          middleName: true,
          lastName: true,
          firstName: true,
          profession: true,
          email: false,
          telephone: true,
          roleList: false,
        }},on:{"saveUser":_vm.updateUserData,"blockUser":_vm.fetchBlockUser,"removeUser":_vm.fetchRemoveUser,"reloadUser":_vm.fetchUserById}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }