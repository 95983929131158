<template>
  <AppMainContainer
    center-class="offer"
  >
    <template #center>
      <div
        v-if="isLoading"
        class="user-info__action-panel"
      >
        <AppLoader />
      </div>
      <div v-else>
        <UserInfoList
          v-if="userInfo"
          :user-info="userInfo"
          :edit-list="{
            office: true,
            middleName: true,
            lastName: true,
            firstName: true,
            profession: true,
            email: false,
            telephone: true,
            roleList: false,
          }"
          @saveUser="updateUserData"
          @blockUser="fetchBlockUser"
          @removeUser="fetchRemoveUser"
          @reloadUser="fetchUserById"
        />
      </div>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import officeApi from '@/mixins/office/officeApi'
import UserInfoList from '@/components/users/UserInfoList'
import userUtil from '@/utils/user-util'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'PageUserItem',
  components: {
    UserInfoList,
    AppLoader,
    AppMainContainer
  },
  mixins: [officeApi],
  props: {
    userId: {
      type: String,
    }
  },
  data () {
    return {
      isLoading: false,
      userInfo: null,
    }
  },
  created () {
    this.$store.commit('users/setEditedUser', null)
    this.$store.commit('office/fetchOfficeListWithCommit', [])
    this.$store.dispatch('office/fetchOfficeListWithCommit')
    this.fetchUserById()
  },
  methods: {
    fetchUserConnectToOffice (userData) {
      this.$store.dispatch('users/fetchUserConnectToOffice', userData)
        .then((response) => {
          this.$store.commit('notification/addNotification',
            {
              message: 'Изменения сохранены',
              type: 'Text'
            }
          )
          this.fetchUserById()
        })
    },
    updateUserData (userData) {
      this.$store.dispatch('users/updateUserData', { userData })
        .then((response) => {
          this.$store.commit('notification/addNotification',
            {
              message: 'Изменения сохранены',
              type: 'Text'
            }
          )
          this.fetchUserById()
        })
    },

    fetchUserById () {
      this.$store.dispatch('users/fetchUserById', this.$route.params.userId)
        .then((response) => {
          let user = response.data
          this.userInfo = response.data
          if (Array.isArray(response.data.roleList) && response.data.roleList.length) {
            this.userInfo.role = userUtil.getRoleById(response.data.roleList[0])
          }
          if (userUtil.isOfficeManager(user)) {
            if (user.office && user.office.id) {
              user = {
                ...this.userInfo,
                officeList: [user.office]
              }
              this.userInfo.officeList = [{ id: user.office.id }]
            }
          }
          this.$store.commit('users/setEditedUser', user)
        })
    },

    async fetchBlockUser (user, unblock = false) {
      try {
        this.isLoading = true
        const method = user.status === 'blocked' ? 'fetchUserUnBlock' : 'fetchUserBlock'
        await this.$store.dispatch(`users/${method}`, user)
          .then(() => {
            this.fetchUserById()
          })
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },

    fetchRemoveUser (user) {
      try {
        this.isLoading = true
        this.$store.dispatch('users/fetchUserBlock', user)
          .then(() => {
            this.fetchUserById()
          })
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.filter-button
  display: flex
  align-items: center
  width: 400px

.user-list__circle
  background-color: #DDDDDD
  width: 10px
  height: 10px
  border-radius: 20px
  &_active
    background-color: #8BD118

.user-form__input-group
  display: flex
  flex-direction: column
  margin-top: 15px
  padding-bottom: 15px
  border-bottom: $default_border_bottom
.input-group__input
  margin-top: 15px

.user-form__button-group
  display: flex
  margin-top: 30px
</style>
